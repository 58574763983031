<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()

const { t, n, locale } = useI18n()
const localePath = useLocalePath()

const sitedata: any = useSiteData()

const currency = useCookie('currency')

const currencyKey = getCurrencyKey(locale.value, currency.value)
</script>

<template>
  <ClientOnly fallback-tag="span">
    <div class="mt-10 flex items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
      <div v-if="checkValue(props?.data?.unit_price)" class="text-3xl font-bold">
        {{ n(getUnitPrice(props?.data, currencyKey), 'currency', currencyKey) }}
      </div>
      <div v-else />

      <div v-if="props?.data?.qty === 0" class="text-center text-orange-600">
        {{ t('out_of_stock') }}
      </div>
      <UButton
        v-else
        icon="i-heroicons-arrow-small-right-20-solid"
        size="xl"
        color="black"
        variant="solid"
        :label="t('booking_room')"
        :trailing="false"
        padded
        truncate
        :ui="{
          base: 'basis-1/2 justify-center mt-10',
          padding: {
            xl: 'px-6 py-3',
          },
        }"
        @click="navigateTo(localePath(`/${sitedata?.booking_room_slug ?? ''}`))"
      />
    </div>

    <template #fallback>
      <div class="mt-10 flex items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
        <USkeleton class="h-12 w-[20%]" />
        <USkeleton class="h-12 w-[50%]" />
      </div>
    </template>
  </ClientOnly>
</template>
