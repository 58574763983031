export default function (date1: string, date2: string) {
  const a = new Date(date1)
  const b = new Date(date2)
  const difference = dateDiffInDays(a, b)
  return difference
}

// a and b are javascript Date objects
function dateDiffInDays(a: Date, b: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate())
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate())

  return Math.floor((utc2 - utc1) / _MS_PER_DAY)
}
