<script setup lang="ts">
import { destr } from 'destr'

const props = defineProps<{
  data?: any
  date?: any
}>()

const emit = defineEmits(['change'])

const { t, n, locale } = useI18n()

const sitedata: any = useSiteData()

const currency = useCookie('currency')

const currencyKey = getCurrencyKey(locale.value, currency.value)

const cartStorageKey = getCartStorageKey({ site_type: sitedata.value?.site_type, locale: locale.value, currency: currency.value })

const cartStorage: any = import.meta.client ? destr(localStorage.getItem(cartStorageKey)) : {}

const cart: any = ref(cartStorage ?? {})

const dateValue = ref<any>(props?.date)

const times = computed(() => diffDates(_get(dateValue.value, 0), _get(dateValue.value, 1)))

const qty = ref<any>(1)

const adultsValue = ref<any>(1)

const childrenValue = ref<any>(0)

const childrenAge = ref<any>([])

const childrenAgeDefault = 10
const childrenAgeMax = 17

const addCartLoading = ref(false)

async function onSelect() {
  if (!qty.value && !adultsValue.value && !childrenValue.value)
    return false

  const data = {
    qty: qty.value,
    adults: adultsValue.value,
    children: childrenValue.value,
    childrenage: childrenAge.value,
  }

  emit('change', data)
}

function qtyDecrease() {
  qty.value--

  onSelect()
}

function qtyIncrease() {
  qty.value++

  onSelect()
}

function adultsValueDecrease() {
  adultsValue.value--

  onSelect()
}

function adultsValueIncrease() {
  adultsValue.value++

  onSelect()
}

function childrenValueDecrease() {
  childrenValue.value--

  childrenAge.value.pop()

  onSelect()
}

function childrenValueIncrease() {
  if (childrenAge.value?.length > childrenAgeMax - 1)
    return false

  childrenValue.value++

  childrenAge.value.push(childrenAgeDefault)

  onSelect()
}

async function saveCart() {
  if (cart.value?.items?.length === 0 && !cart.value?.id)
    return false

  const host = await useGetHost()

  const data: any = await $fetch('/api/cart', {
    method: 'post',
    headers: { domain: host, locale: locale.value },
    body: cart.value,
  })

  if (data && !cart.value?.id)
    _set(cart.value, 'id', data)

  return false
}

async function onAddToCart(item: any) {
  if (dateValue.value?.length === 0) {
    toastAdd({ title: t('empty_date'), color: 'red' })
    return false
  }
  if (!times.value) {
    toastAdd({ title: t('empty_times'), color: 'red' })
    return false
  }
  if (!qty.value) {
    toastAdd({ title: t('empty_qty'), color: 'red' })
    return false
  }

  addCartLoading.value = true

  const index = _findIndex(cart.value?.items, { id: item?.id })

  if (index > -1)
    cart.value.items = [...cart.value?.items.slice(0, index), ...cart.value?.items.slice(index + 1)]

  const cartItem: any = _pick(item, ['id', 'name', 'slug', 'image', 'unit', 'unit_price', 'vat'])

  if (sitedata.value?.currencies?.length)
    _set(cartItem, 'unit_price', getUnitPrice(item, currencyKey))

  if (props?.data?.order_type)
    _set(cartItem, 'order_type', props?.data?.order_type)

  _set(cartItem, 'dates', dateValue.value)

  _set(cartItem, 'times', times.value)

  _set(cartItem, 'qty', qty.value)

  _set(cartItem, 'adults', adultsValue.value)

  _set(cartItem, 'children', childrenValue.value)

  _set(cartItem, 'childrenage', childrenAge.value)

  const items = _uniqBy(_concat(cart.value?.items ?? [], cartItem), 'id')

  cart.value.items = items

  if (sitedata.value?.currencies?.length && currency.value)
    _set(cart.value, 'currency', currency.value)

  await saveCart()

  if (import.meta.client)
    localStorage.setItem(cartStorageKey, JSON.stringify(cart.value))

  addCartLoading.value = false

  toastAdd({ title: t('added_to_cart', { name: cartItem?.name ?? '' }) })

  return false
}
</script>

<template>
  <ClientOnly fallback-tag="span">
    <div v-if="props?.data?.qty === 0" class="text-center text-orange-600">
      {{ t('out_of_stock_room') }}
    </div>
    <div v-else class="mt-10 space-y-4 border rounded-lg py-4 sm:flex-row sm:space-y-0">
      <div class="w-full p-2 text-lg">
        <p class="text-gray-600 font-bold mb-2 block">
          {{ t('checkin_checkout') }}
        </p>
        <DatePicker
          name="eyHcba"
          :date="dateValue"
          input-class-name="w-full"
          @change="(v: any) => dateValue = v"
        />
      </div>
      <div class="w-full p-2 text-lg">
        <p class="text-gray-600 font-bold mb-2 block">
          {{ t('rooms') }}
        </p>

        <UPopover
          class="h-full w-100"
          :ui="{ width: 'w-100' }"
        >
          <UButton
            :label="
              (qty || adultsValue || childrenValue)
                ? `${t('rooms')}: ${qty}, ${t('adults')}: ${adultsValue}, ${t('children')}: ${childrenValue}`
                : t('guests_rooms')"
            color="white"
            variant="solid"
            block
            size="md"
          />

          <template #panel>
            <div class="p-5 pb-2 pt-5 w-80">
              <div class="flex items-center justify-between w-full mb-5">
                <div class="w-3/5">
                  {{ t('rooms') }}
                </div>
                <div class="w-2/5 flex items-center justify-between">
                  <div>
                    <UButton
                      :disabled="qty <= 1"
                      icon="i-heroicons-minus"
                      size="xs"
                      color="primary"
                      square
                      variant="outline"
                      :ui="{ rounded: 'rounded-full' }"
                      @click="qtyDecrease"
                    />
                  </div>
                  <div class="px-2">
                    {{ qty }}
                  </div>
                  <div>
                    <UButton
                      icon="i-heroicons-plus"
                      size="xs"
                      color="primary"
                      square
                      variant="outline"
                      :ui="{ rounded: 'rounded-full' }"
                      @click="qtyIncrease"
                    />
                  </div>
                </div>
              </div>
              <div class="flex items-center justify-between w-full mb-5">
                <div class="w-3/5">
                  {{ t('adults') }}
                </div>
                <div class="w-2/5 flex items-center justify-between">
                  <div>
                    <UButton
                      :disabled="adultsValue <= 1"
                      icon="i-heroicons-minus"
                      size="xs"
                      color="primary"
                      square
                      variant="outline"
                      :ui="{ rounded: 'rounded-full' }"
                      @click="adultsValueDecrease"
                    />
                  </div>
                  <div class="px-2">
                    {{ adultsValue }}
                  </div>
                  <div>
                    <UButton
                      icon="i-heroicons-plus"
                      size="xs"
                      color="primary"
                      square
                      variant="outline"
                      :ui="{ rounded: 'rounded-full' }"
                      @click="adultsValueIncrease"
                    />
                  </div>
                </div>
              </div>
              <div class="flex items-center justify-between w-full mb-5">
                <div class="w-3/5">
                  {{ t('children') }}
                </div>
                <div class="w-2/5 flex items-center justify-between">
                  <div>
                    <UButton
                      :disabled="childrenValue <= 0"
                      icon="i-heroicons-minus"
                      size="xs"
                      color="primary"
                      square
                      variant="outline"
                      :ui="{ rounded: 'rounded-full' }"
                      @click="childrenValueDecrease"
                    />
                  </div>
                  <div class="px-2">
                    {{ childrenValue }}
                  </div>
                  <div>
                    <UButton
                      :disabled="childrenAge?.length > 9"
                      icon="i-heroicons-plus"
                      size="xs"
                      color="primary"
                      square
                      variant="outline"
                      :ui="{ rounded: 'rounded-full' }"
                      @click="childrenValueIncrease"
                    />
                  </div>
                </div>
              </div>
              <div
                v-for="(item, index) in childrenAge"
                :key="`AWBjmU${index}`"
                class="flex items-center justify-between w-full mb-5"
              >
                <div class="w-3/5">
                  {{ `${t('age_of_child')} ${index + 1}` }}
                </div>
                <div class="w-2/5">
                  <USelect
                    v-model="childrenAge[index]"
                    :name="`vQpIat_${index}`"
                    size="sm"
                    :options="Array.from({ length: childrenAgeMax }, (_, i) => i + 1)"
                    @update:model-value="onSelect"
                  />
                </div>
              </div>
            </div>
          </template>
        </UPopover>
      </div>
      <div class="w-full p-2 text-lg">
        <p class="text-gray-600 font-bold mb-2 block">
          {{ t('price_detail') }}
        </p>
        <div v-if="times && qty" class="flex items-center justify-between">
          <div>
            <span v-if="checkValue(props?.data?.unit_price)" class="text-xl">
              {{ `${qty} ${t('room')} x ${times} ${t('night')} x ${n((props?.data?.unit_price ?? 0), 'currency', currencyKey)}` }}
            </span>
            <span v-if="checkValue(props?.data?.vat)">
              {{ `(${(props?.data?.vat ?? 0) * 1}% VAT)` }}
            </span>
          </div>
          <div v-if="checkValue(props?.data?.unit_price)" class="text-3xl font-bold">
            {{ n(qty * times * (props?.data?.unit_price ?? 0) * (1 + (props?.data?.vat ?? 0) * 1 / 100), 'currency', currencyKey) }}
          </div>
        </div>
        <div v-else>
          <div v-if="checkValue(props?.data?.unit_price)">
            {{ `${n((props?.data?.unit_price ?? 0), 'currency', currencyKey)}/${t('rooms')}` }}
          </div>
        </div>
      </div>
      <div class="w-full p-2 pt-10 text-lg flex items-center justify-end">
        <UButton
          :loading="addCartLoading"
          icon="i-heroicons-shopping-bag-solid"
          size="xl"
          color="black"
          variant="solid"
          :label="t('add_to_cart')"
          :trailing="false"
          padded
          truncate
          :ui="{
            base: 'w-full justify-center',
            padding: {
              xl: 'px-6 py-3',
            },
          }"
          @click="onAddToCart(props?.data)"
        />
      </div>
    </div>

    <template #fallback>
      <LoadingSpinner />
    </template>
  </ClientOnly>
</template>
