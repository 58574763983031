<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()

const { locale } = useI18n()

const isauth = computed(() => _has(props?.data, 'isauth') ? (props?.data?.isauth ?? false) : false)

const authenticated = useAuthenticated()

const route = useRoute()

const query = ref(route?.query)

const queryNoPage = computed(() => _omit(query.value, ['page']))

const token: any = useCookie('token')

const host = await useGetHost()

const { data: userdata }: any = await useFetch('/api/userdata', {
  headers: { token: token.value, domain: host, locale },
})

const state = computed(() => Object.assign(setColumnValue(props?.data?.columns, _isObject(userdata.value) ? userdata.value : {}), queryNoPage.value))
</script>

<template>
  <div class="mx-auto max-w-screen-xl">
    <div class="container m-auto px-2 text-gray-600 md:px-4 xl:px-2">
      <ArticleTitle :data="props?.data" />

      <ContentView
        v-if="props?.data?.content"
        :content="props?.data?.content"
        class="mx-auto max-w-screen-md pb-10 text-xl tracking-wide text-gray-700 article-content"
      />

      <div v-if="props?.data?.columns">
        <div v-if="authenticated || (!authenticated && !isauth)">
          <FormInput
            :isauth="isauth"
            :columns="props?.data?.columns"
            :state="state"
            :submit-center="true"
            class="max-w-screen-md px-3"
          />
        </div>

        <ButtonLoginToContinue v-else />
      </div>
    </div>
  </div>
</template>
