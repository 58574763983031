<script setup lang="ts">
import { destr } from 'destr'

const props = defineProps<{
  data?: any
}>()

const { t, n, locale } = useI18n()

const sitedata: any = useSiteData()

const currency = useCookie('currency')

const currencyKey = getCurrencyKey(locale.value, currency.value)

const cartStorageKey = getCartStorageKey({ site_type: sitedata.value?.site_type, locale: locale.value, currency: currency.value })

const cartStorage: any = import.meta.client ? destr(localStorage.getItem(cartStorageKey)) : {}

const cart: any = ref(cartStorage ?? {})

const addCartLoading = ref(false)

const times = computed(() => sitedata.value?.payment_period ?? 1)

async function saveCart() {
  if (cart.value?.items?.length === 0 && !cart.value?.id)
    return false

  const host = await useGetHost()

  const data: any = await $fetch('/api/cart', {
    method: 'post',
    headers: { domain: host, locale: locale.value },
    body: cart.value,
  })

  if (data && !cart.value?.id)
    _set(cart.value, 'id', data)

  return false
}

async function onAddToCart(item: any) {
  addCartLoading.value = true

  // check item in cart
  const index = _findIndex(cart.value?.items, { id: item?.id })

  if (index > -1) {
    addCartLoading.value = false
    toastAdd({ title: t('added_to_cart', { name: item?.name ?? '' }) })
    return false
  }

  const cartItem: any = _pick(item, ['id', 'name', 'slug', 'image', 'unit', 'unit_price', 'vat'])

  if (sitedata.value?.currencies?.length)
    _set(cartItem, 'unit_price', getUnitPrice(item, currencyKey))

  if (props?.data?.order_type)
    _set(cartItem, 'order_type', props?.data?.order_type)

  _set(cartItem, 'times', times.value ?? 1)

  _set(cartItem, 'qty', 1)

  const items = _uniqBy(_concat(cart.value?.items ?? [], cartItem), 'id')

  cart.value.items = items

  if (sitedata.value?.currencies?.length && currency.value)
    _set(cart.value, 'currency', currency.value)

  await saveCart()

  if (import.meta.client)
    localStorage.setItem(cartStorageKey, JSON.stringify(cart.value))

  addCartLoading.value = false

  toastAdd({ title: t('added_to_cart', { name: cartItem?.name ?? '' }) })

  return false
}

async function saveCartShop() {
  cart.value?.items_grouped?.forEach(async (group: any, index: any) => {
    let shopCart = _omit(cart.value, ['items_grouped'])

    shopCart = Object.assign(shopCart, group)

    const host = await useGetHost()

    const data: any = await $fetch('/api/cart', {
      method: 'post',
      headers: { domain: host, locale: locale.value },
      body: shopCart,
    })

    if (!group?.id && data) {
      _set(cart.value, `items_grouped.${index}.id`, data)

      if (import.meta.client)
        localStorage.setItem(cartStorageKey, JSON.stringify(cart.value))
    }
  })
}

async function onAddToCartShop(item: any) {
  addCartLoading.value = true

  const indexGroup = _findIndex(cart.value?.items_grouped, { shop_id: item?.shop_id })

  const items = _get(cart.value?.items_grouped, `${indexGroup}.items`)

  const index = _findIndex(items, { id: item?.id })

  if (index > -1) {
    addCartLoading.value = false
    toastAdd({ title: t('added_to_cart', { name: item?.name ?? '' }) })
    return false
  }

  const cartItem: any = _pick(item, ['id', 'name', 'slug', 'image', 'unit', 'unit_price', 'vat'])

  if (sitedata.value?.currencies?.length)
    _set(cartItem, 'unit_price', getUnitPrice(item, currencyKey))

  _set(cartItem, 'times', times.value ?? 1)

  _set(cartItem, 'qty', 1)

  const itemsGroup = _uniqBy(_concat(items ?? [], cartItem), 'id')

  if (indexGroup > -1) {
    _set(cart.value, `items_grouped.${indexGroup}.items`, itemsGroup)
  }
  else {
    if (cart.value?.items_grouped?.length) {
      cart.value.items_grouped = [
        ...cart.value.items_grouped,
        ...[
          {
            items: itemsGroup,
            shop_id: item?.shop_id,
            shop_name: item?.shop_name,
            shop_slug: item?.shop_slug,
          },
        ],
      ]
    }
    else {
      _set(cart.value, 'items_grouped.0', {
        items: itemsGroup,
        shop_id: item?.shop_id,
        shop_name: item?.shop_name,
        shop_slug: item?.shop_slug,
      })
    }
  }

  if (sitedata.value?.currencies?.length && currency.value)
    _set(cart.value, 'currency', currency.value)

  await saveCartShop()

  if (import.meta.client)
    localStorage.setItem(cartStorageKey, JSON.stringify(cart.value))

  addCartLoading.value = false

  toastAdd({ title: t('added_to_cart', { name: cartItem?.name ?? '' }) })

  return false
}

async function onClick(item: any) {
  if (sitedata.value?.site_type === 4)
    await onAddToCartShop(item)

  else
    await onAddToCart(item)
}
</script>

<template>
  <ClientOnly fallback-tag="span">
    <div class="mt-10 flex items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
      <div v-if="checkValue(props?.data?.unit_price)" class="text-3xl font-bold">
        {{ n(getUnitPrice(props?.data, currencyKey), 'currency', currencyKey) }}
      </div>
      <div v-else />

      <div v-if="props?.data?.qty === 0" class="text-center text-orange-600">
        {{ t('out_of_stock') }}
      </div>
      <UButton
        v-else
        :loading="addCartLoading"
        icon="i-heroicons-shopping-bag-solid"
        size="xl"
        color="black"
        variant="solid"
        :label="t('add_to_cart')"
        :trailing="false"
        padded
        truncate
        :ui="{
          base: 'basis-1/2 justify-center',
          padding: {
            xl: 'px-6 py-3',
          },
        }"
        @click="onClick(props?.data)"
      />
    </div>

    <template #fallback>
      <div class="mt-10 flex items-center justify-between space-y-4 border-t border-b py-4 sm:flex-row sm:space-y-0">
        <USkeleton class="h-12 w-[20%]" />
        <USkeleton class="h-12 w-[50%]" />
      </div>
    </template>
  </ClientOnly>
</template>
