<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()

const { d, t } = useI18n()
const localePath = useLocalePath()

const items = computed(() => props.data?.items ?? [])
</script>

<template>
  <div v-if="items?.length">
    <div
      v-for="(item, index) in items"
      :key="`ZKnKPd${index}`"
      class="cursor-pointer group relative my-2 p-4 sm:p-6 rounded-3xl bg-white border border-transparent hover:border-gray-100 shadow-2xl shadow-transparent hover:shadow-gray-600/10 sm:gap-8 sm:flex transition duration-300 hover:z-10"
      @click="navigateTo(item?.slug ? localePath(`/${item?.slug}`) : localePath('/'))"
    >
      <div class="sm:w-2/6 rounded-3xl overflow-hidden transition-all duration-500 group-hover:rounded-xl">
        <NuxtImg
          v-if="item?.image && checkImage(item?.image)"
          loading="lazy"
          :src="item?.image"
          :alt="item?.name"
          class="h-56 sm:h-full w-full object-cover object-top transition duration-500 group-hover:scale-105"
        />
      </div>

      <div class="sm:p-2 sm:pl-0 sm:w-4/6">
        <span v-if="item?.created_at" class="mt-4 mb-2 inline-block font-medium text-gray-400 sm:mt-0">
          {{ d(Date.parse(item?.created_at), 'short') }}
        </span>
        <h3 class="text-2xl font-semibold text-gray-800">
          {{ item?.name }}
        </h3>
        <p class="my-6 text-xl text-gray-600">
          {{ item?.summary }}
        </p>

        <!-- <div v-if="item?.tags?.length" class="flex gap-4">
          <a
            v-for="(ite, ind) in item?.tags"
            :key="`lPekdx${ind}`"
            class="rounded-lg bg-gray-100 px-2 py-1 font-medium text-gray-600 hover:bg-gray-200"
          >
            {{ ite }}
          </a>
        </div> -->
      </div>
    </div>

    <slot name="pagination" />
  </div>

  <div v-else class="mx-auto text-center text-lg">
    {{ t('search_no_items') }}
  </div>
</template>
