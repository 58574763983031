<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()

const viewport = useViewport()

const { t } = useI18n()
const localePath = useLocalePath()

const url = useRequestURL()

const sitedata: any = useSiteData()

const selectedImageIndex = ref(0)

const breadcrumbLinks = ref([
  {
    label: t('homepage'),
    icon: 'i-heroicons-home',
    to: localePath('/'),
  },
  {
    label: props?.data?.name,
    to: localePath(props?.data?.slug),
  },
])
</script>

<template>
  <section>
    <div class="container mx-auto max-w-screen-xl px-4">
      <UBreadcrumb
        :links="breadcrumbLinks"
        :ui="{
          base: 'font-normal',
          li: 'text-base',
          active: 'text-gray-400',
        }"
      />

      <div class="lg:col-gap-12 xl:col-gap-16 mt-8 grid grid-cols-1 gap-12 lg:mt-12 lg:grid-cols-5 lg:gap-16">
        <div class="lg:col-span-3 lg:row-end-1">
          <div class="lg:flex lg:items-start">
            <div class="lg:order-2 lg:ml-5">
              <div class="max-w-xl overflow-hidden rounded-lg w-full lg:w-[30rem] xl:w-[40rem]">
                <NuxtImg
                  v-if="props?.data?.image && checkImage(_get(props?.data?.image, `${selectedImageIndex}.link`))"
                  loading="lazy"
                  :src="_get(props?.data?.image, `${selectedImageIndex}.link`)"
                  :alt="props?.data?.name"
                  class="h-full w-full max-w-full object-cover"
                />
              </div>
            </div>

            <div v-if="props?.data?.image?.length > 1" class="mt-2 w-full lg:order-1 lg:w-[5.5rem] lg:flex-shrink-0 lg:h-[23.75rem] lg:overflow-y-auto">
              <div class="flex flex-row items-start lg:flex-col">
                <button
                  v-for="(item, index) in props?.data?.image"
                  :key="`dQYBpg${index}`"
                  type="button"
                  :class="selectedImageIndex === index
                    ? 'flex-0 aspect-square mb-3 w-20 h-20 overflow-hidden rounded-lg border-2 text-center border-gray-300'
                    : 'flex-0 aspect-square mb-3 w-20 h-20 overflow-hidden rounded-lg border-2 text-center border-transparent'"
                  @click="selectedImageIndex = index"
                >
                  <NuxtImg
                    v-if="item?.link && checkImage(item?.link)"
                    loading="lazy"
                    :src="item?.link"
                    :alt="props?.data?.name"
                    class="h-full w-full object-cover"
                  />
                </button>
              </div>
            </div>
          </div>

          <div v-if="viewport.isGreaterThan('tablet') && props?.data?.description" class="lg:col-span-3 mt-10">
            <div class="border-b border-gray-300">
              <nav class="flex gap-4">
                <div class="border-b-2 border-gray-900 py-4 text-base font-bold text-gray-600 hover:border-gray-400 hover:text-gray-800">
                  {{ t('describe') }}
                </div>
              </nav>
            </div>

            <ContentView v-if="props?.data?.description" :content="props?.data?.description" class="mt-8 flow-root sm:mt-12 text-xl tracking-wide article-content" />
          </div>
        </div>

        <div class="lg:col-span-2 lg:row-span-2 lg:row-end-2">
          <h1 class="text-3xl font-bold text-gray-900">
            {{ props?.data?.name }}
          </h1>

          <div class="mt-5 flex items-center">
            <div class="flex items-center">
              <svg class="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class="" />
              </svg>
              <svg class="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class="" />
              </svg>
              <svg class="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class="" />
              </svg>
              <svg class="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class="" />
              </svg>
              <svg class="block h-4 w-4 align-middle text-yellow-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" class="" />
              </svg>
            </div>
          </div>

          <div v-if="props?.data?.category?.length" class="mt-6 flex flex-wrap justify-start gap-2" aria-label="Tags">
            <button
              v-for="(item, index) in props?.data?.category"
              :key="`PVnYRv${index}`"
              class="rounded-lg bg-gray-100 px-2 py-1 font-medium text-gray-600 hover:bg-gray-200"
              @click="navigateTo(item?.slug ? localePath(`/${item?.slug}`) : localePath('/'))"
            >
              {{ item?.label }}
            </button>
          </div>

          <div v-if="sitedata?.hascart">
            <ButtonAddCartRoom v-if="sitedata?.site_type === 3" :data="props?.data" />
            <ButtonAddCartMain v-else :data="props?.data" />
          </div>

          <ButtonBooking v-if="sitedata?.site_type === 5" :data="props?.data" />

          <ButtonInvest :data="props?.data" class="mt-8" />

          <ul class="mt-8 space-y-2">
            <li v-if="props?.data?.hotel" class="flex items-center text-left text-lg font-medium text-gray-600">
              <UIcon name="i-heroicons-star-solid" class="text-gray-500 mr-2 w-6 h-6" />
              {{ props?.data?.hotel }}
            </li>

            <li v-if="props?.data?.address" class="flex items-center text-left text-lg font-medium text-gray-600">
              <UIcon name="i-heroicons-map-pin-solid" class="text-gray-500 mr-2 w-6 h-6" />
              {{ props?.data?.address }}
            </li>

            <li v-if="props?.data?.capacity" class="flex items-center text-left text-lg font-medium text-gray-600">
              <UIcon name="i-heroicons-building-office-solid" class="text-gray-500 mr-2 w-6 h-6" />
              {{ props?.data?.capacity }}
            </li>
          </ul>

          <div v-if="props?.data?.utilities?.length" class="mt-8 space-y-2">
            <div class="flex items-center text-left text-lg font-medium text-gray-600">
              <UIcon name="i-heroicons-star-solid" class="text-gray-500 mr-2 w-6 h-6" />
              {{ t('utilities') }}
            </div>
            <div class="mt-6 flex flex-wrap justify-start gap-2" aria-label="Tags">
              <span
                v-for="(item, index) in props?.data?.utilities"
                :key="`GgeMea${index}`"
                class="rounded-lg bg-gray-100 px-2 py-1 font-medium text-gray-600 hover:bg-gray-200"
              >
                {{ item }}
              </span>
            </div>
          </div>

          <ShopCard v-if="props?.data?.shopdata" :data="props?.data?.shopdata" :isshopname="true" />

          <SupportSocial />

          <QrCode
            v-if="url.href"
            :data="url.href"
            caption-class="w-full mx-auto"
          />
        </div>

        <div v-if="viewport.isLessOrEquals('tablet') && props?.data?.description" class="lg:col-span-3">
          <div class="border-b border-gray-300">
            <nav class="flex gap-4">
              <div class="border-b-2 border-gray-900 py-4 text-base font-bold text-gray-600 hover:border-gray-400 hover:text-gray-800">
                {{ t('describe') }}
              </div>
            </nav>
          </div>

          <ContentView v-if="props?.data?.description" :content="props?.data?.description" class="mt-8 flow-root sm:mt-12 text-xl tracking-wide article-content" />
        </div>

        <div v-if="sitedata?.hascart" class="block sm:hidden">
          <ButtonAddCartRoom v-if="sitedata?.site_type === 3" :data="props?.data" />
          <ButtonAddCartMain v-else :data="props?.data" />
        </div>
      </div>
    </div>
  </section>
</template>
