<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()

const { t, locale } = useI18n()
</script>

<template>
  <aside v-if="!props?.data?.type && props?.data?.items?.length" aria-label="Related Articles" class="mx-auto mt-10 max-w-screen-xl py-20 px-2 md:px-4 xl:px-2">
    <h2 class="mb-8 text-center text-4xl sm:text-5xl font-bold text-gray-900">
      {{ t('more_blogs') }}
    </h2>

    <div class="mx-auto grid max-w-screen-lg justify-center px-4 sm:grid-cols-2 sm:gap-6 sm:px-8 md:grid-cols-3">
      <article
        v-for="(item, index) in props?.data?.items"
        :key="`sCPriu${index}`"
        class="mx-auto my-4 flex flex-col overflow-hidden rounded-lg border border-gray-300 bg-white text-gray-900 transition hover:translate-y-2 hover:shadow-lg"
      >
        <NuxtLinkLocale :to="item?.slug ? `/${item?.slug}` : '/'" :hreflang="locale">
          <NuxtImg
            v-if="item?.image && checkImage(item?.image)"
            loading="lazy"
            :src="item?.image"
            alt=""
            class="h-56 w-full object-cover"
          />
          <div class="flex-auto px-6 py-5">
            <span class="mb-2 flex items-center text-sm font-semibold">
              <svg xmlns="http://www.w3.org/2000/svg" class="mr-2 h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                <path stroke-linecap="round" stroke-linejoin="round" d="M7 20l4-16m2 16l4-16M6 9h14M4 15h14" />
              </svg>
              {{ item?.category_data?.length ? _join(item?.category_data?.map((x: any) => x?.name), ', ') : _join(item?.category, ', ') }}
            </span>
            <h3 class="mt-4 mb-3 text-xl font-semibold xl:text-2xl">
              {{ item?.name }}
            </h3>
            <p class="mb-4 text-base font-light">
              {{ item?.summary }}
            </p>
          </div>
        </NuxtLinkLocale>
      </article>
    </div>
  </aside>
</template>
