<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()

const viewport = useViewport()

const url = useRequestURL()

const localePath = useLocalePath()

const route = useRoute()

// http/blog/article
// http/blog
// http/blog/1
// http/blog/2
// http/about
// http/contact

// const slug = route.params.slug ?? ''
const slug = computed(() => _get(route.params.slug ?? [], 0, ''))
const paramPage = computed(() => Number(_get(route.params.slug ?? [], 1, 1)))

const page = ref(paramPage.value)

const query = ref(route?.query)

const queryNoPage = computed(() => _omit(query.value, ['page']))
</script>

<template>
  <div class="mx-auto max-w-screen-xl">
    <div class="container m-auto px-2 text-gray-600">
      <ArticleTitle :data="props?.data" />

      <SearchProduct />

      <div class="mx-auto">
        <ProductList :data="props?.data">
          <template #pagination>
            <UPagination
              v-if="props?.data?.last_page > 1"
              v-model="page"
              :total="props?.data?.total ?? 0"
              :max="5"
              :page-count="props?.data?.per_page ?? 20"
              :active-button="{ color: 'gray' }"
              size="xl"
              class="my-10 justify-end"
              :ui="{ rounded: 'first-of-type:rounded-s-md last-of-type:rounded-e-md' }"
              @click="navigateTo({ path: slug ? localePath(`/${slug}/${page}`) : localePath('/'), query: queryNoPage })"
            >
              <template #prev="{ onClick }">
                <UTooltip text="Previous page">
                  <UButton icon="i-heroicons-arrow-small-left-20-solid" color="gray" :ui="{ rounded: 'rounded-full' }" class="rtl:[&_span:first-child]:rotate-180 me-2" @click="onClick" />
                </UTooltip>
              </template>
              <template #next="{ onClick }">
                <UTooltip text="Next page">
                  <UButton icon="i-heroicons-arrow-small-right-20-solid" color="gray" :ui="{ rounded: 'rounded-full' }" class="rtl:[&_span:last-child]:rotate-180 ms-2" @click="onClick" />
                </UTooltip>
              </template>
            </UPagination>
          </template>
        </ProductList>

        <div v-if="viewport.isLessOrEquals('tablet')">
          <SupportSocial />

          <QrCode
            v-if="url?.href"
            :data="url?.href"
          />
        </div>
      </div>
    </div>
  </div>
</template>
