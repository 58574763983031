<script setup lang="ts">
definePageMeta({
  layout: false,
})

const { locale } = useI18n()

const url = useRequestURL()

const host = await useGetHost()

const route = useRoute()

// http/blog/article
// http/blog
// http/blog/1
// http/blog/2
// http/about
// http/contact

// const slug = route.params.slug ?? ''
const slug = computed(() => _get(route.params.slug ?? [], 0, ''))
const paramPage = computed(() => Number(_get(route.params.slug ?? [], 1, 1)))

const page = ref(paramPage.value)

const query = ref(route?.query)

if (page.value)
  _set(query.value, 'page', page.value)

if (route?.query?.keyword)
  _set(query.value, 'keyword', route?.query?.keyword)

// if (route?.query?.tag)
//   _set(query.value, 'tag', route?.query?.tag)

// const queryNoPage = computed(() => _omit(query.value, ['page']))

const { data }: any = await useFetch('/api/slug', {
  headers: { domain: host, locale: locale.value, slug: slug.value },
  query: query.value,
})

const sitedata: any = useSiteData()

const metaImage = computed(() =>
  data.value?.meta_image
    ? data.value?.meta_image
    : (
        _isArray(data.value?.image)
          ? _get(data.value?.image, '0.link')
          : data.value?.image
      ),
)

useHead({
  title: data.value?.meta_title || data.value?.name,
  meta: [
    { name: 'description', content: data.value?.meta_description || data.value?.summary },
    { property: 'og:title', content: data.value?.meta_title || data.value?.name },
    { property: 'og:description', content: data.value?.meta_description || data.value?.summary },
    { property: 'og:image', content: metaImage.value || sitedata.value?.meta_image || sitedata.value?.logo },
    // { property: 'og:image:type', content: 'image/png' },
    // { property: 'og:image:width', content: '300' },
    // { property: 'og:image:height', content: '300' },
    // { property: 'og:image:alt', content: host },
    { property: 'og:type', content: 'article' },
    { property: 'og:site_name', content: data.value?.meta_title || data.value?.name },
    { property: 'og:url', content: url.href },
    // { name: 'twitter:card', content: 'summary_large_image' },
    // { name: 'twitter:site', content: `@${host}` },
    // { name: 'twitter:title', content: data.value?.meta_title || data.value?.name },
    // { name: 'twitter:description', content: data.value?.meta_description || data.value?.summary },
    // { name: 'twitter:image', content: metaImage.value },
  ],
  link: [
    {
      hid: 'icon',
      rel: 'icon',
      type: 'image/x-icon',
      href: sitedata.value?.favicon ? sitedata.value?.favicon : null,
    },
  ],
})
</script>

<template>
  <div>
    <NuxtLayout :name="sitedata?.layout ?? 'default'">
      <EmptyPage v-if="_isEmpty(data)" />

      <ArticlePage v-else-if="data?.type === 1" :data="data" />

      <ProductPage v-else-if="data?.type === 5" :data="data" />

      <FormMain v-else-if="data?.type === 3" :data="data" />

      <FormPayment v-else-if="data?.type === 8" :data="data" />

      <ContactMain v-else-if="data?.type === 4" :data="data" />

      <ProductMain v-else-if="data?.isproduct" :data="data" />

      <ArticleMain v-else :data="data" />
    </NuxtLayout>
  </div>
</template>
