<script setup lang="ts">
const props = defineProps<{
  slug?: string
}>()

const { t, locale } = useI18n()
const localePath = useLocalePath()

const route = useRoute()

const keyword = ref<any>(route?.query?.keyword ?? '')

const isOpen = ref(false)

const host = await useGetHost()

const headers: any = { domain: host, locale: locale.value, slug: props?.slug }

const groups = [{
  key: 'posts',
  label: (q: any) => q && `${t('results_matching')} “${q}”...`,
  search: async (q: any) => {
    if (!q || q.length < 3)
      return []

    const data: any = await $fetch('/api/search-post', {
      headers,
      query: { q },
    })

    return data.map((post: any) => ({ id: post?.id, label: post?.name, to: localePath(`/${post?.slug}`) }))
  },
}]

async function onSelect() {
  if (!keyword.value) {
    isOpen.value = true
    return false
  }

  isOpen.value = false

  await navigateTo({ path: localePath(`/${props?.slug}`), query: { keyword: keyword.value } })
}
</script>

<template>
  <div class="mx-auto max-w-screen-lg px-2 mb-10 sm:mb-20">
    <div class="container mx-auto">
      <div class="flex items-center justify-center">
        <div class="flex w-full mx-10 rounded-lg border-2 p-1">
          <UInput
            v-model="keyword"
            type="search"
            name="keywordpost"
            color="gray"
            variant="none"
            :placeholder="t('search')"
            class="w-full h-10 border-none bg-transparent text-gray-600 outline-none focus:outline-none text-lg"
            size="xl"
            :ui="{
              label: {
                base: 'text-lg',
              },
            }"
            @keyup.enter="onSelect"
            @click="isOpen = true"
          />

          <UButton
            aria-label="search-post"
            icon="i-heroicons-magnifying-glass-20-solid"
            size="xl"
            color="black"
            square
            variant="solid"
            @click="onSelect"
          />
        </div>
      </div>
    </div>

    <div>
      <UModal
        v-model="isOpen"
        :ui="{
          width: 'w-full sm:max-w-5xl',
        }"
      >
        <UCommandPalette
          :groups="groups"
          :autoselect="false"
          :empty-state="{ icon: 'i-heroicons-magnifying-glass-20-solid', label: t('no_items'), queryLabel: t('no_items_try') }"
          :placeholder="t('type_something')"
          :ui="{
            input: {
              size: 'sm:text-xl',
            },
            emptyState: {
              label: 'text-xl',
              queryLabel: 'text-xl',
            },
            group: {
              label: 'text-xl',
              container: 'text-xl',
              command: {
                base: 'py-2.5',
              },
            },
          }"
          @update:model-value="(option: any) => navigateTo(option.to)"
        />
      </UModal>
    </div>
  </div>
</template>
