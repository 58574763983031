<script setup lang="ts">
import { vi } from 'date-fns/locale'

const props = defineProps<{
  date?: any
}>()

const emit = defineEmits(['change'])

const { t, locale } = useI18n()

const dateValue = ref<any>(props?.date)
</script>

<template>
  <VueDatePicker
    v-model="dateValue"
    range
    multi-calendars
    auto-apply
    :min-date="new Date()"
    model-type="yyyy-MM-dd"
    :format="locale === 'vi' ? 'dd-MM-yyyy' : 'MM-dd-yyyy'"
    :locale="locale"
    :enable-time-picker="false"
    :cancel-text="t('cancel')"
    :select-text="t('select')"
    :format-locale="locale === 'vi' ? vi : null"
    @update:model-value="emit('change', dateValue)"
  />
</template>
