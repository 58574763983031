<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()

const { t, d, locale } = useI18n()

const isauth = computed(() => _has(props?.data, 'isauth') ? (props?.data?.isauth ?? false) : false)

const authenticated = useAuthenticated()

const route = useRoute()

const query = ref(route?.query)

const queryNoPage = computed(() => _omit(query.value, ['page']))

const token: any = useCookie('token')

const host = await useGetHost()

const { data: userdata }: any = await useFetch('/api/userdata', {
  headers: { token: token.value, domain: host, locale },
})

const state = computed(() => Object.assign(setColumnValue(props?.data?.columns, _isObject(userdata.value) ? userdata.value : {}), queryNoPage.value))

const slugCategory = computed(() => _get(props?.data?.category, 0))
</script>

<template>
  <main>
    <article class="px-2 md:px-4 xl:px-2">
      <div class="mx-auto max-w-screen-xl text-center">
        <SearchPost v-if="props?.data?.type !== 2" :slug="slugCategory" />

        <p v-if="props?.data?.created_at" class="mx-auto max-w-screen-md text-gray-500 mb-2">
          {{ `${t('published')} ${d(Date.parse(props?.data?.created_at), 'short')}` }}
        </p>
        <h1 class="text-4xl font-bold text-gray-800 sm:text-5xl">
          {{ props?.data?.name }}
        </h1>
        <p class="mx-auto max-w-screen-md mt-6 tracking-wide text-xl text-gray-800 article-content">
          {{ props?.data?.summary }}
        </p>
        <div v-if="props?.data?.tags?.length" class="mx-auto max-w-screen-md mt-6 flex flex-wrap justify-center gap-2" aria-label="Tags">
          <button
            v-for="(item, index) in props?.data?.tags"
            :key="`uyuceK${index}`"
            class="rounded-lg bg-gray-100 px-2 py-1 font-medium text-gray-600 hover:bg-gray-200"
          >
            {{ item }}
          </button>
        </div>
      </div>

      <ContentView v-if="props?.data?.content" :content="props?.data?.content" class="mx-auto max-w-screen-md py-10 text-xl tracking-wide text-gray-700 article-content" />
    </article>
  </main>

  <div v-if="props?.data?.columns">
    <FormInput
      v-if="authenticated || (!authenticated && !isauth)"
      :isauth="isauth"
      :columns="props?.data?.columns"
      :state="state"
      :params="{ ispost: 1 }"
      class="max-w-screen-md border rounded-md px-8"
    >
      <template #title>
        <div class="my-10 block text-center">
          <h3 class="text-4xl font-bold text-gray-700">
            {{ props?.data?.form_name }}
          </h3>
        </div>
      </template>
    </FormInput>

    <ButtonLoginToContinue v-else />
  </div>

  <ArticleDivider v-if="!props?.data?.type && props?.data?.items?.length" />

  <ArticleRelated v-if="!props?.data?.type && props?.data?.items?.length" :data="props?.data" />
</template>
