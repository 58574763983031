<script setup lang="ts">
const props = defineProps<{
  data?: any
}>()
</script>

<template>
  <div class="mx-auto max-w-screen-xl">
    <div class="container m-auto px-2 text-gray-600 md:px-4 xl:px-2">
      <ArticleTitle :data="props?.data" />

      <div class="mx-auto flex w-full max-w-screen-lg flex-col overflow-hidden rounded-xl text-gray-900 md:flex-row md:border md:shadow-lg">
        <FormInput
          :columns="props?.data?.columns"
          class="max-w-2xl"
        />

        <div class="mt-10 bg-blue-600 px-10 py-8 text-gray-100 md:mt-0 md:ml-auto">
          <ContentView v-if="props?.data?.content" :content="props?.data?.content" />
        </div>
      </div>
    </div>
  </div>
</template>
